import React from "react";
import Slider from "react-slick";
import mouthImage from "./images/Mouth/Original(2).png";
import mouthImage2 from "./images/Mouth/Rabbitteeth.png";
import mouthImage3 from "./images/Mouth/glas.png";
import mouthImage4 from "./images/Mouth/MuradHair.png";
import mouthImage5 from "./images/Mouth/RainbowAfro.png";
import mouthImage6 from "./images/Mouth/roaringkittyheadband.png";
import bodyImage from "./images/Body/Originalbody.png";
import bodyImage2 from "./images/Body/Neon.png";
import bodyImage3 from "./images/Body/beer.png";
import bodyImage4 from "./images/Body/grasss.png";
import bodyImage5 from "./images/Body/green.png";
import bodyImage6 from "./images/Body/red.png";
import bodyImage7 from "./images/Body/stars.png";
import bodyImage8 from "./images/Body/ye.png";
import bodyImage9 from "./images/Body/WhiteSkin.png";
import hatsImage from "./images/Hats/Capss.png";
import hatsImage2 from "./images/Hats/Headband.png";
import hatsImage3 from "./images/Hats/moooney.png";
import hatsImage4 from "./images/Hats/,jh.png";
import hatsImage5 from "./images/Hats/pf.png";
import eyesImage from "./images/Eyes/Bigeyes.png";
import eyesImage2 from "./images/Eyes/Original(1).png";
import eyesImage3 from "./images/Eyes/sus.png";
import eyesImage4 from "./images/Eyes/smail.png";
import eyesImage5 from "./images/Eyes/Mcfries.png";
import eyesImage6 from "./images/Eyes/gok.png";
import clothesImage from "./images/Clothes/Bipp.png";
import clothesImage2 from "./images/Clothes/Cape.png";
import clothesImage3 from "./images/Clothes/mhand.png";
import clothesImage4 from "./images/Clothes/LaserEyes.png";
import clothesImage5 from "./images/Clothes/PitViper.png";
import clothesImage6 from "./images/Clothes/Shades.png";
import clothesImage7 from "./images/Clothes/SolanaTattoo.png";
import clothesImage8 from "./images/Clothes/lipstick.png";
import backgroundImage from "./images/Background/Bg1.png";
import backgroundImage2 from "./images/Background/Bg2.png";
import backgroundImage3 from "./images/Background/Bg3.png";
import backgroundImage4 from "./images/Background/Bg4.png";
import backgroundImage5 from "./images/Background/Bg5.png";
import "./styles.css";

function SelectionPanel({ avatarParts, setAvatarParts }) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
  };
  return (
    <div className="selectionPanel">
      {/* Buttons for Mouth */}
      <h1>Nugget Meme Generator</h1>
      <div className={"section"}>
        <h3>Background</h3>
        <Slider {...settings}>
          <button
            className={`selectionbuttons ${
              avatarParts.Background === backgroundImage ? "selected" : ""
            }`}
            id="bg1"
            onClick={() =>
              setAvatarParts({ ...avatarParts, Background: backgroundImage })
            }
            style={{ width: "20px", height: "80px", margin: "0 10px" }}
          ></button>
          <button
            class={`selectionbuttons ${
              avatarParts.Background === backgroundImage2 ? "selected" : ""
            }`}
            id="bg2"
            onClick={() =>
              setAvatarParts({ ...avatarParts, Background: backgroundImage2 })
            }
          ></button>
          <button
            class={`selectionbuttons ${
              avatarParts.Background === backgroundImage3 ? "selected" : ""
            }`}
            id="bg3"
            onClick={() =>
              setAvatarParts({ ...avatarParts, Background: backgroundImage3 })
            }
          ></button>
          <button
            class={`selectionbuttons ${
              avatarParts.Background === backgroundImage4 ? "selected" : ""
            }`}
            id="bg4"
            onClick={() =>
              setAvatarParts({ ...avatarParts, Background: backgroundImage4 })
            }
          ></button>
                    <button
            class={`selectionbuttons ${
              avatarParts.Background === backgroundImage5 ? "selected" : ""
            }`}
            id="bg5"
            onClick={() =>
              setAvatarParts({ ...avatarParts, Background: backgroundImage5 })
            }
          ></button>
        </Slider>
      </div>
      <div className="section">
        <h3>Nuggets</h3>
        <Slider {...settings}>
          <button
            class={`selectionbuttons ${
              avatarParts.Body === bodyImage ? "selected" : ""
            }`}
            id="originalbody"
            onClick={() => setAvatarParts({ ...avatarParts, Body: bodyImage })}
          ></button>
          <button
            class={`selectionbuttons ${
              avatarParts.Body === bodyImage2 ? "selected" : ""
            }`}
            id="neonbody"
            onClick={() => setAvatarParts({ ...avatarParts, Body: bodyImage2 })}
          ></button>
          <button
            class={`selectionbuttons ${
              avatarParts.Body === bodyImage3 ? "selected" : ""
            }`}
            id="beerbody"
            onClick={() => setAvatarParts({ ...avatarParts, Body: bodyImage3 })}
          ></button>
          <button
            class={`selectionbuttons ${
              avatarParts.Body === bodyImage4 ? "selected" : ""
            }`}
            id="grasssbody"
            onClick={() => setAvatarParts({ ...avatarParts, Body: bodyImage4 })}
          ></button>
          <button
            class={`selectionbuttons ${
              avatarParts.Body === bodyImage5 ? "selected" : ""
            }`}
            id="greenbody"
            onClick={() => setAvatarParts({ ...avatarParts, Body: bodyImage5 })}
          ></button>
          <button
            class={`selectionbuttons ${
              avatarParts.Body === bodyImage6 ? "selected" : ""
            }`}
            id="redbody"
            onClick={() => setAvatarParts({ ...avatarParts, Body: bodyImage6 })}
          ></button>
          <button
            class={`selectionbuttons ${
              avatarParts.Body === bodyImage7 ? "selected" : ""
            }`}
            id="starsbody"
            onClick={() => setAvatarParts({ ...avatarParts, Body: bodyImage7 })}
          ></button>
          <button
            class={`selectionbuttons ${
              avatarParts.Body === bodyImage8 ? "selected" : ""
            }`}
            id="yebody"
            onClick={() => setAvatarParts({ ...avatarParts, Body: bodyImage8 })}
          ></button>
                    <button
            class={`selectionbuttons ${
              avatarParts.Body === bodyImage9 ? "selected" : ""
            }`}
            id="whitebody"
            onClick={() => setAvatarParts({ ...avatarParts, Body: bodyImage9 })}
          ></button>
        </Slider>
      </div>
      <div className="section">
        <h3>Face Accessories</h3>
        <Slider {...settings}>
        <button
          class={`selectionbuttons ${
            avatarParts.Clothes === clothesImage ? "selected" : ""
          }`}
          id="bip"
          onClick={() =>
            setAvatarParts({ ...avatarParts, Clothes: clothesImage })
          }
        ></button>
        <button
          class={`selectionbuttons ${
            avatarParts.Clothes === clothesImage2 ? "selected" : ""
          }`}
          id="cape"
          onClick={() =>
            setAvatarParts({ ...avatarParts, Clothes: clothesImage2 })
          }
        ></button>
        <button
          class={`selectionbuttons ${
            avatarParts.Clothes === clothesImage3 ? "selected" : ""
          }`}
          id="mhand"
          onClick={() =>
            setAvatarParts({ ...avatarParts, Clothes: clothesImage3 })
          }
        ></button>
                <button
          class={`selectionbuttons ${
            avatarParts.Clothes === clothesImage4 ? "selected" : ""
          }`}
          id="clothes4"
          onClick={() =>
            setAvatarParts({ ...avatarParts, Clothes: clothesImage4 })
          }
        ></button>
                        <button
          class={`selectionbuttons ${
            avatarParts.Clothes === clothesImage5 ? "selected" : ""
          }`}
          id="clothes5"
          onClick={() =>
            setAvatarParts({ ...avatarParts, Clothes: clothesImage5 })
          }
        ></button>
                        <button
          class={`selectionbuttons ${
            avatarParts.Clothes === clothesImage6 ? "selected" : ""
          }`}
          id="clothes6"
          onClick={() =>
            setAvatarParts({ ...avatarParts, Clothes: clothesImage6 })
          }
        ></button>
                                <button
          class={`selectionbuttons ${
            avatarParts.Clothes === clothesImage7 ? "selected" : ""
          }`}
          id="clothes7"
          onClick={() =>
            setAvatarParts({ ...avatarParts, Clothes: clothesImage7 })
          }
        ></button>
                                <button
          class={`selectionbuttons ${
            avatarParts.Clothes === clothesImage8 ? "selected" : ""
          }`}
          id="clothes8"
          onClick={() =>
            setAvatarParts({ ...avatarParts, Clothes: clothesImage8 })
          }
        ></button>
        </Slider>
      </div>
      <div className="section">
        <h3>Hands</h3>
        <Slider {...settings}>
        <button
          id="originaleyes"
          class={`selectionbuttons ${
            avatarParts.Eyes === eyesImage2 ? "selected" : ""
          }`}
          onClick={() => setAvatarParts({ ...avatarParts, Eyes: eyesImage2 })}
        ></button>
        <button
          id="bigeyes"
          class={`selectionbuttons ${
            avatarParts.Eyes === eyesImage ? "selected" : ""
          }`}
          onClick={() => setAvatarParts({ ...avatarParts, Eyes: eyesImage })}
        ></button>
        <button
          id="sus"
          class={`selectionbuttons ${
            avatarParts.Eyes === eyesImage3 ? "selected" : ""
          }`}
          onClick={() => setAvatarParts({ ...avatarParts, Eyes: eyesImage3 })}
        ></button>
        <button
          id="smail"
          class={`selectionbuttons ${
            avatarParts.Eyes === eyesImage4 ? "selected" : ""
          }`}
          onClick={() => setAvatarParts({ ...avatarParts, Eyes: eyesImage4 })}
        ></button>
                <button
          id="smail5"
          class={`selectionbuttons ${
            avatarParts.Eyes === eyesImage5 ? "selected" : ""
          }`}
          onClick={() => setAvatarParts({ ...avatarParts, Eyes: eyesImage5 })}
        ></button>
                <button
          id="smail6"
          class={`selectionbuttons ${
            avatarParts.Eyes === eyesImage6 ? "selected" : ""
          }`}
          onClick={() => setAvatarParts({ ...avatarParts, Eyes: eyesImage6 })}
        ></button>
        </Slider>
      </div>
      <div className="section">
        <h3>Clothes</h3>
        <Slider {...settings}>
          <button
            class={`selectionbuttons ${
              avatarParts.Hats === hatsImage ? "selected" : ""
            }`}
            id="cap"
            onClick={() => setAvatarParts({ ...avatarParts, Hats: hatsImage })}
          ></button>
          <button
            class={`selectionbuttons ${
              avatarParts.Hats === hatsImage2 ? "selected" : ""
            }`}
            id="headband"
            onClick={() => setAvatarParts({ ...avatarParts, Hats: hatsImage2 })}
          ></button>
          <button
            class={`selectionbuttons ${
              avatarParts.Hats === hatsImage3 ? "selected" : ""
            }`}
            id="mooney"
            onClick={() => setAvatarParts({ ...avatarParts, Hats: hatsImage3 })}
          ></button>
          <button
            class={`selectionbuttons ${
              avatarParts.Hats === hatsImage4 ? "selected" : ""
            }`}
            id="bm"
            onClick={() => setAvatarParts({ ...avatarParts, Hats: hatsImage4 })}
          ></button>
          <button
            class={`selectionbuttons ${
              avatarParts.Hats === hatsImage5 ? "selected" : ""
            }`}
            id="pf"
            onClick={() => setAvatarParts({ ...avatarParts, Hats: hatsImage5 })}
          ></button>
        </Slider>
      </div>
      <div className="section">
        <h3>Hats</h3>
        <Slider {...settings}>
        <button
          class={`selectionbuttons ${
            avatarParts.Mouth === mouthImage ? "selected" : ""
          }`}
          id="originalmouth"
          onClick={() => setAvatarParts({ ...avatarParts, Mouth: mouthImage })}
        ></button>
        <button
          class={`selectionbuttons ${
            avatarParts.Mouth === mouthImage2 ? "selected" : ""
          }`}
          id="rabbitmouth"
          onClick={() => setAvatarParts({ ...avatarParts, Mouth: mouthImage2 })}
        ></button>
        <button
          class={`selectionbuttons ${
            avatarParts.Mouth === mouthImage3 ? "selected" : ""
          }`}
          id="glas"
          onClick={() => setAvatarParts({ ...avatarParts, Mouth: mouthImage3 })}
        ></button>
                <button
          class={`selectionbuttons ${
            avatarParts.Mouth === mouthImage4 ? "selected" : ""
          }`}
          id="glas4"
          onClick={() => setAvatarParts({ ...avatarParts, Mouth: mouthImage4 })}
        ></button>
                <button
          class={`selectionbuttons ${
            avatarParts.Mouth === mouthImage5 ? "selected" : ""
          }`}
          id="glas5"
          onClick={() => setAvatarParts({ ...avatarParts, Mouth: mouthImage5 })}
        ></button>
                <button
          class={`selectionbuttons ${
            avatarParts.Mouth === mouthImage6 ? "selected" : ""
          }`}
          id="glas6"
          onClick={() => setAvatarParts({ ...avatarParts, Mouth: mouthImage6 })}
        ></button>
        </Slider>
      </div>
    </div>
  );
}
function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "blue" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "blue" }}
      onClick={onClick}
    />
  );
}
export default SelectionPanel;
