import React, { useState } from "react";
import AvatarCanvas from "./AvatarCanvas";
import SelectionPanel from "./SelectionPanel";
import mouthImage from "./images/Mouth/Original(2).png";
import bodyImage from "./images/Body/Originalbody.png";
import hatsImage from "./images/Hats/Capss.png";
import eyesImage from "./images/Eyes/Bigeyes.png";
import clothesImage from "./images/Clothes/Bipp.png";
import backgroundImage from "./images/Background/Bg1.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./styles.css";
function App() {
  // Example state initialization and update method in App.js
  const [avatarParts, setAvatarParts] = useState({
    Mouth: mouthImage,
    Body: bodyImage,
    Hats: hatsImage,
    Eyes: eyesImage,
    Clothes: clothesImage,
    Background: backgroundImage,
  });
  return (
    <div className="app">
      <div className="content">
        <div className="selectionpanel">
          <SelectionPanel
            avatarParts={avatarParts}
            setAvatarParts={setAvatarParts}
          />
        </div>
        <div className="avatarContainer">
          <AvatarCanvas avatarParts={avatarParts} />
        </div>
      </div>
      <footer className="footer">
        Made By{" "}
        <a
          href="https://t.me/Captainzip"
          target="_blank"
          rel="noopener noreferrer"
        >
          @CaptainZip
        </a>
      </footer>
    </div>
  );
}

export default App;
