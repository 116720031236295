import React, { useEffect, useRef } from "react";
import html2canvas from "html2canvas";
import "./styles.css";
function AvatarCanvas({ avatarParts }) {
  // Order the parts according to the layerin
  const canvasRef = useRef(null);

  const handleSave = () => {
    if (canvasRef.current === null) {
      return;
    }

    html2canvas(canvasRef.current, {
      scale: 2, // Optional: Increase scale for higher resolution
      useCORS: true, // Enable this if you're loading images from external sources
    })
      .then((canvas) => {
        const dataUrl = canvas.toDataURL("image/png");
        const link = document.createElement("a");
        link.href = dataUrl;
        link.download = "avatar.png";
        link.click(); // Trigger the download
      })
      .catch((err) => {
        console.log("Error saving the image:", err);
      });
  };
  const orderedParts = [
    "Background",
    "Body",
    "Clothes",
    "Mouth",
    "Hats",
    "Eyes",
  ];

  return (
    <div>
      <div
        className="avatarCanvas"
        style={{
          position: "relative",
          height: "400px",
          width: "400px",
          overflow: "hidden",
          border: "2px solid #ffae0c",
        }}
      >
        <div
          style={{
            position: "relative",
            height: "100%",
            width: "100%",
          }}
          ref={canvasRef} // Attach the ref to the inner canvas for saving purposes
        >
          {orderedParts.map(
            (part) =>
              avatarParts[part] && (
                <img
                  key={part}
                  src={avatarParts[part]}
                  alt={part}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                  }}
                />
              ),
          )}
        </div>
      </div>
      <button onClick={handleSave} className="saveButton">
        SAVE NUGGET
      </button>
    </div>
  );
}
export default AvatarCanvas;
